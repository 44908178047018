import { KeyValueList, KeyValueRow, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useFieldValue, useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize, prefFieldName } from "@aeo/core/utils";

import { KeyValueCard, NotesCardForm, PersonListProps } from "../components";
import { NoteForm } from "../components/NoteForm";

export const CommercialActivityValues = ({ path }: PersonListProps) => {
  const commercial = fields.commercialActivity;
  const subdivision = fields.commercialSubdivision;
  const person = fields.person;

  const value = useFieldValue(path);

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...commercial.description} />
      <KeyValueCard {...commercial.roles} type="nomenclature-list" />
      <p className="color-gray">{messages.titles.subdivisionsActivity}</p>

      <WhiteSpace />

      <p className="color-gray">{messages.titles.individualSubdivisions}</p>
      <KeyValueList
        noStyle
        ident={1}
        name={[commercial.subdivisions.name, "value"]}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(subdivision.name, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.address, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.phone, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.email, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.activity, prefix)} />
            <KeyValueRow
              {...prefFieldName(subdivision.employee_count, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(subdivision.exists_third_party, prefix)}
              type="boolean"
            />
            <WhiteSpace v="1rem" />
            <NoteForm note={value?.[commercial.subdivisions.name]?.note} />

            <KeyValueList
              title={`${messages.titles.thirdPartySubdivision} ${String(
                messages.titles.juridicPerson,
              ).toLowerCase()}`}
              ident={1}
              name={[
                ...(prefix as string[]),
                subdivision.third_party_juridic.name,
              ]}
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow {...prefFieldName(person.name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.idno, prefix)} />
                  <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                  <KeyValueRow {...prefFieldName(person.address, prefix)} />
                  <KeyValueRow
                    {...prefFieldName(subdivision.third_party, prefix)}
                  />
                  <KeyValueRow
                    {...prefFieldName(subdivision.employee_count, prefix)}
                  />
                  <WhiteSpace v="1rem" />
                  <NoteForm
                    note={value?.[subdivision.third_party_juridic.name]?.note}
                  />
                </>
              )}
            />

            <KeyValueList
              title={`${messages.titles.thirdPartySubdivision} ${String(
                messages.titles.physicPerson,
              ).toLowerCase()}`}
              ident={1}
              name={[
                ...(prefix as string[]),
                subdivision.third_party_physic.name,
              ]}
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.birthday, prefix)} />
                  <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
                  <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                  <KeyValueRow {...prefFieldName(person.email, prefix)} />
                  <KeyValueRow {...prefFieldName(person.address, prefix)} />
                  <KeyValueRow
                    {...prefFieldName(subdivision.third_party, prefix)}
                  />
                  <WhiteSpace v="1rem" />
                  <NoteForm
                    note={value?.[subdivision.third_party_physic.name]?.note}
                  />
                </>
              )}
            />
          </>
        )}
      />

      <KeyValueCard {...commercial.buy_from_associated} type="boolean" />
      <KeyValueList
        title={messages.titles.associatedCompany}
        name={[commercial.associated_buy_companies.name, "value"]}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <WhiteSpace v="1rem" />
            <NoteForm
              note={value?.[commercial.associated_buy_companies.name]?.note}
            />
          </>
        )}
      />

      <KeyValueCard {...commercial.sell_to_associated} type="boolean" />
      <KeyValueList
        title={messages.titles.associatedCompany}
        name={[commercial.associated_sell_companies.name, "value"]}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <WhiteSpace v="1rem" />
            <NoteForm
              note={value?.[commercial.associated_sell_companies.name]?.note}
            />
          </>
        )}
      />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...commercial.attachments} type="attachments" />
    </>
  );
};

export const CommercialActivity = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.commercial.title}
      content={<CommercialActivityValues />}
      path="saq/commercial"
    />
  );
};
