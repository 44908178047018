import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const ComplianceLegislationValues = () => {
  const legislation = fields.complianceLegislation;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{messages.titles.legislationSubsection}</h4>
      <WhiteSpace />
      <KeyValueCard
        {...legislation.has_breaches_legislation}
        type="boolean-no-na"
      />
      <KeyValueCard {...legislation.violations_description} NA />
      <KeyValueCard {...legislation.notify_violations} NA />
      <KeyValueCard {...legislation.introduced_measures} NA />
      <KeyValueCard {...legislation.register_measures} type="boolean" NA />
      <KeyValueCard {...legislation.convicted_economic_crimes} type="boolean" />
      <KeyValueCard {...legislation.economic_crimes} NA />
      <KeyValueCard {...legislation.apply_other_customs} type="boolean" />
      <KeyValueCard {...legislation.other_customs} NA />
      <KeyValueCard {...legislation.any_refused_applications} type="boolean" />
      <KeyValueCard {...legislation.refused_applications} NA />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...legislation.attachments} type="attachments" />
    </>
  );
};

export const ComplianceLegislation = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.legislation.title}
      content={<ComplianceLegislationValues />}
      path="saq/legislation"
    />
  );
};
