export const practicalStandard = {
  has_experience: {
    name: "has_experience",
    label:
      "5.1.1. Aveți dvs. sau persoana responsabilă de domeniul vamal experiență practică de cel puțin trei ani în domeniul vamal?",
    dependencies: ["experience"],
    info: "5.1.1. Posibilele mijloace prin care se poate dovedi experienţa practică de cel puţin trei ani în domeniul vamale sunt descrise în detaliu în Orientările tematice, publicate de Organizaţia Mondială a Vămilor şi Comisia Europeană .",
    defaultDepsValue: [{ experience_attachments: [] }],
  },
  experience_attachments: {
    name: "experience_attachments",
    label: "Atașați document confirmativ",
  },
  experience: {
    name: "experience",
    label:
      "Dacă răspunsul este da, furnizaţi detalii prin care să dovediți experiența.",
    placeholder: "Detalii",
    dependencies: ["has_experience"],
  },
  has_quality_standard: {
    name: "has_quality_standard",
    label:
      "5.1.2. Dețineți dvs. sau persoana responsabilă de domeniul vamal un standard de calitate în domeniul vamal, adoptat de organismul național de standardizare?",
    dependencies: ["quality_standard"],
    info: "5.1.2. Furnizați informațiidacă dispuneți de un standard de calitate în domeniul vamal, adoptat de organismul național de standardizare. ",
    defaultDepsValue: [{ quality_standard_attachments: [] }],
  },
  quality_standard: {
    name: "quality_standard",
    label:
      "Dacă răspunsul este da, furnizaţi informații privind standardul deținut.",
    placeholder: "Detalii",
    dependencies: ["has_quality_standard"],
  },
  quality_standard_attachments: {
    name: "quality_standard_attachments",
    label: "Atașați document confirmativ",
  },
  graduated_training_course: {
    name: "graduated_training_course",
    label:
      "5.2.1. Ați absolvit sau persoana responsabilă de domeniul vamal un curs de formare pe tema legislației vamale corespunzător implicării dvs. în activitățile din domeniul vamal?",
    dependencies: ["training_course"],
    info: "5.2.1. Furnizați informațiiîn ceea ce priveşte cursul de instruire privind legislaţia vamală, corespunzător implicării în activităţile din domeniul vamal.",
    defaultDepsValue: [{ training_course_attachments: [] }],
  },
  training_course: {
    name: "training_course",
    label:
      "Dacă răspunsul este da, furnizați detalii cu privire la cursul de formare absolvit.",
    placeholder: "Detalii",
    dependencies: ["graduated_training_course"],
  },
  training_course_attachments: {
    name: "training_course_attachments",
    label: "Documente atașate",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
