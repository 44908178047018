import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const SecurityOneValues = () => {
  const securityOne = fields.securityOne;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.securityOneSub)}</h4>
      <WhiteSpace />
      <KeyValueCard
        {...securityOne.competent.first_name}
        customPathName={["competent", "value", "first_name"]}
      />
      <KeyValueCard
        {...securityOne.competent.last_name}
        customPathName={["competent", "value", "last_name"]}
      />
      <KeyValueCard
        {...securityOne.competent.function}
        customPathName={["competent", "value", "function"]}
      />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_assessment_risk} type="boolean" />
      <KeyValueCard {...securityOne.has_security_plan} type="boolean" />
      <KeyValueCard
        {...securityOne.security_plan_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.documents_reviewed} />
      <KeyValueCard {...securityOne.risks} />
      <KeyValueCard {...securityOne.measures} />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_subdivision_measures} type="boolean" />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...securityOne.has_instructions} type="boolean" />
      <KeyValueCard {...securityOne.staff_communication} />
      <KeyValueCard {...securityOne.instructions_documented} />
      <KeyValueCard
        {...securityOne.instructions_documented_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_incidents} type="boolean" />
      <KeyValueCard {...securityOne.incidents} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.incidents_documented} type="boolean" />

      <WhiteSpace v="1rem" />
      <KeyValueCard {...securityOne.has_certificate} type="boolean" />
      <KeyValueCard {...securityOne.certificates} type="attachments" NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_apply_security} type="boolean" />
      <KeyValueCard {...securityOne.apply_security} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_special_requirements} type="boolean" />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_security_service} type="boolean" />
      <KeyValueCard {...securityOne.security_service} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_assessment_service} type="boolean" />
      <KeyValueCard {...securityOne.risks_identified} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityOne.has_client_requirements} type="boolean" />
      <KeyValueCard {...securityOne.client_requirements} NA />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityOne.attachments} type="attachments" />
    </>
  );
};

export const SecurityOne = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.security.title}
      content={<SecurityOneValues />}
      path="saq/security_one"
    />
  );
};
