export const titles = {
  requiredRequestData: "Datele necesare pentru cerere",
  requiredDocuments: "Acte necesare",
  contactPerson: "Persoana de contact",
  physicPerson: "Persoană fizică",
  juridicPerson: "Persoană juridică",
  signatory: "Semnatar",
  companyInfo: "Subsecţiunea 1.1. INFORMAȚII GENERALE PRIVIND COMPANIA",
  responsible: "Persoană responsabilă",
  subdivisions: "1.1.5",
  thirdPartySubdivision: "Subdiviziune terță",
  associatedCompany: "Companie asociată",
  thirdParty: "Parte terță",
  auditTrack: "Subsecţiunea 3.1. PISTA DE AUDIT",
  internControl: "Subsecţiunea 3.3. SISTEME DE CONTROL INTERN",
  flowOfGoods: "Subsecţiunea 3.4. FLUXUL MĂRFURILOR",
  customsFormalities: "Subsecţiunea 3.5. FORMALITĂȚILE VAMALE",
  procedures:
    "Subsecţiunea 3.6. PROCEDURI PRIVIND REALIZAREA UNOR COPII DE SIGURANȚĂ, RECUPERAREA DATELOR ȘI SOLUȚII DE REZERVĂ, OPȚIUNI DE ARHIVARE",
  protectionOfSystems: "Subsecţiunea 3.7. PROTECŢIA SISTEMELOR INFORMATICE",
  documentationSecurity: "Subsecţiunea 3.8. SECURITATEA  DOCUMENTAŢIEI",
  practicalStandard: "Subsecţiunea 5.1. STANDARDE PRACTICE DE COMPETENȚĂ",
  professionalQualifications: "Subsecţiunea 5.2. CALIFICĂRI PROFESIONALE",
  buildingSecurity: "Subsectiunea 6.2. SECURITATEA CLĂDIRILOR",
  premisesAccess: "Subsectiunea 6.3. ACCESUL ÎN INCINTE",
  loadingUnits: "Subsectiunea 6.4. UNITĂȚILE DE ÎNCĂRCARE",
  logisticsProcesses: "Subsecţiunea 6.5. PROCESE LOGISTICE",
  enteredGoods: "Subsecţiunea 6.6. MĂRFURI INTRATE",
  goodsStorage: "Subsectiunea 6.7. DEPOZITAREA MĂRFURILOR",
  goodsProduction: "Subsecţiunea 6.8. PRODUCȚIA DE MĂRFURI",
  goodsLoading: "Subsecţiunea 6.9. ÎNCĂRCAREA MĂRFURILOR",
  securityRequirements:
    "Subsectiunea 6.10. CERINȚE DE SECURITATE PENTRU PARTENERII DE AFACERI",
  staffSecurity: "Subsecţiunea 6.11. SECURITATEA PERSONALULUI",
  contractedServices: "Subsecţiunea 6.12. SERVICII CONTRACTATE",
  legislationSubsection: "Subsecţiunea 2.1.",
  economicAgentSubsection: "1.1.1.",
  autorizationsInfo:
    "Pentru întrebările a) şi b), precizați detalii cu privire la autorizaţie şi cerere (denumirea şi numărul de înregistrare).",
  logisticSubsection: "Subsecţiunea 3.2. SISTEME CONTABILE ȘI LOGISTICE",
  securityOneSub:
    "Subsecţiunea 6.1. INFORMAȚII GENERALE PRIVIND SIGURANȚA ȘI SECURITATEA",
  subdivisionsActivity:
    "1.1.5. Precizați subdiviziunile, implicate în activitățile vamale, să enumerați adresele, denumirea, numerele de telefon și e-mailul punctelor de contact și oferiți o scurtă descriere a activității comerciale desfășurate.",
  subdivisionsActivityInfo:
    "Oferiţi detalii privind subdiviziunile implicate în activităţi vamale (dacă deţineţi mai mult de cinci subdiviziuneii implicate în activităţi vamale, furnizaţi numai detaliile privind cele cinci subdiviziuni principale implicate în activităţile vamale) şi adresele celorlalte subdiviziuni implicate în acest tip de activitate.",
  individualSubdivisions:
    "a) Subdiviziunile individuale ale companiei dvs. in calitate de entitate juridică:",
};
