import { KeyValueRow, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize, prefFieldName } from "@aeo/core/utils";
import { Card } from "ebs-design";

import { KeyValueCard, KeyValuesListCard, NotesCardForm } from "../components";

export const LogisticOneValues = () => {
  const logistic = fields.logisticOne;
  const outsourceOrganization = fields.outsourceOrganization;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.auditTrack)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logistic.has_audit_trail} type="boolean" />
      <KeyValueCard {...logistic.audit_trail} NA />
      <WhiteSpace v="1rem" />
      <h4>{capitalize(messages.titles.logisticSubsection)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logistic.accounting_systems} />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...logistic.can_differentiate} type="boolean-no-na" NA />
      <KeyValueCard {...logistic.differentiate} NA />

      <WhiteSpace v="1rem" />

      <KeyValueCard {...logistic.it_activity} NA />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...logistic.has_it_outsourced} type="boolean" />
      <WhiteSpace v="1rem" />
      <KeyValuesListCard
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.name, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.address, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.code_fiscal, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.description, prefix)}
            />
          </>
        )}
        title="Companie"
        name="outsource_organizations"
      />
      <h4>{capitalize(messages.titles.internControl)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logistic.has_internal_control} type="boolean" />
      <KeyValueCard {...logistic.internal_control} NA />
      <KeyValueCard {...logistic.has_audit_control} type="boolean" />
      <KeyValueCard {...logistic.is_include_parcels} type="boolean" />

      <KeyValueCard {...logistic.recent_audit_report} type="attachments" />
      <WhiteSpace v="1rem" />

      <Card className="bordered mb-10">
        <Card.Body className="p-10">
          <div className="color-gray">
            {logistic.incorrect_registration.info}
          </div>
        </Card.Body>
      </Card>

      <KeyValueCard {...logistic.incorrect_registration} />
      <KeyValueCard {...logistic.incorrect_data} />
      <KeyValueCard {...logistic.incorrect_business_processes} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logistic.attachments} type="attachments" />
    </>
  );
};

export const LogisticOne = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.logistic.title}
      content={<LogisticOneValues />}
      path="saq/logistic_one"
    />
  );
};
