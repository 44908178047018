export const customsStatistic = {
  represent: {
    name: "represent",
    label: "Efectuați formalitățile vamale din nume propriu?",
  },
  carry_formalities: {
    name: ["represent", "carry_formalities"],
    label: "1.3.1. a) Efectuați formalitățile vamale din nume propriu?",
    info: "1.3.1. Furnizați informații privind formalitățile vamale efectuate. ",
    defaultDepsValue: [
      { "represent.represent_persons": [] },
      { "represent.represent_persons_attachments": [] },
    ],
  },
  represent_persons: {
    name: "represent",
    label: `b) Sunteți reprezentat de cineva în ceea ce privește formalitățile vamale, dacă da, de
către cine și în ce mod(direct sau indirect)? Indicați numele, adresa și codul fiscal al
reprezentantului`,
  },
  has_represent: {
    name: "has_represent",
    label: "c) Reprezentați alte persoane în formalitățile vamale?",
    defaultDepsValue: [{ clients: [] }, { attachments_clients: [] }],
  },
  attachments_clients: {
    name: "attachments_clients",
    label: "Lista de clienți",
  },
  who_decide_classification: {
    name: "who_decide_classification",
    label: "1.3.2. a) În ce mod şi cine decide clasificarea mărfurilor?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
    info: `1.3.2. Pentru întrebarea de la litera a), precizați numele şi funcţia angajatului responsabil de clasificarea mărfurilor sau dacă apelaţi la un terţ pentru desfăşurarea acestei activităţi indicați denumirea/numele acestuia. Pentru întrebările de la literele b) şi d), în special, dacă apelaţi la un terţ, cum vă asiguraţi că această activitate a fost desfăşurată în mod corect şi în conformitate cu instrucţiunile? Pentru întrebarea de la litera b) precizaţi dacă păstraţi un dosar al produsului în care fiecărui articol îi corespunde un cod al mărfurilor cu cota corespunzătoare a taxelor vamale şi a TVA. Pentru întrebarea de la litera c) în cazul în care s-au introdus măsuri de asigurare a calităţii, trebuie să furnizaţi dovezi, în timpul vizitei auditorilor vamali, care să ateste că le revizuiţi în mod regulat şi complet, că documentaţi modificările şi înştiinţaţi personalul vizat cu privire la modificările respective. Pentru întrebarea de la litera d), precizaţi modul, persoana responsabilă şi frecvenţa cu care se revizuiesc clasificările şi se actualizează dosarul produsului şi alte evidenţe conexe, precum şi modalitatea de înştiinţare a persoanelor vizate cu privire la modificări, de exemplu, reprezentantul în vamă, personalul responsabil de achiziţii. Pentru întrebarea de la litera e), precizaţi, de asemenea, dacă folosiţi informaţii tarifare obligatorii (ITO).
  Pregătiţi procesul de audit, în caz de necesitate să puneţi la dispoziţie următoarele:
  	<ul>
  		<li>a) detalii/liste sau dosare ale produselor, precum şi codurile de mărfuri şi cotele corespunzătoare ale taxelor vamale;</li>
  		<li>b) sursele de documentare/informaţiile, de exemplu, informaţii actualizate de ordin tehnic pe care le folosiţi,ca regulă, pentru clasificarea mărfurilor.</li>
  	</ul>`,
  },
  classification_measures: {
    name: "classification_measures",
    label:
      "b) De ce măsuri dispuneți de a garanta corectitudinea clasificării mărfurilor (de exemplu, verificări, controale interne, proceduri interne de lucru, instruiri periodice)?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
  },
  register_measures: {
    name: "register_measures",
    label: "c) Păstraţi registre privind aceste măsuri?",
  },
  monitoring_measures: {
    name: "monitoring_measures",
    label: "d) Monitorizaţi regulat eficacitatea măsurilor de care dispuneți?",
  },
  resource_measures: {
    name: "resource_measures",
    label:
      "e) Ce resurse utilizaţi pentru clasificarea mărfurilor (de exemplu, baze de date privind mărfurile)?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
  },
  determine_values: {
    name: "determine_values",
    label:
      "1.3.3. a) Care este modalitatea determinării, declarării valorii în vamă și cine stabileşte valoarea în vamă a mărfurilor?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
    info: `1.3.3. Pentru întrebarea de la litera a), indicaţi numele şi funcţia angajatului responsabil de determinarea valorii mărfurilor în vamă sau, dacă apelaţi la un terţ pentru realizarea acestei activităţi, precizaţi denumirea/numele acestuia. Pentru întrebările de la literele b)şi d) dacă apelaţi la un terţ, cum vă asiguraţi că această activitate a fost realizată în mod corect şi în conformitate cu instrucţiunile existente? Pentru întrebarea de la litera b) măsurile de asigurare a calităţii trebuie să includă, de exemplu:
  <ul>
<li>a) metoda (metodele) de evaluare utilizată (utilizate);</li>
<li>b) modul în care sunt completate şi transmise declaraţiile pentru determinarea valorii în vamă, atunci când este necesar;</li>
<li>c) modul în care sunt stabilite valoarea taxelor vamale şi valoarea TVA-ului;</li>
<li>d) modul în care sunt contabilizate costurile de transport şi de asigurare;</li>
<li>e) redevenţele şi drepturile de licenţă aferente mărfurilor importate, plătibile fie direct, fie indirect de către cumpărător, ca o condiţie a vânzării;</li>
<li>f) acorduri în temeiul cărora o parte din profitul din revânzări, dispuneri sau utilizări ulterioare revine direct sau indirect vânzătorului;</li>
<li>g) costuri suportate de cumpărător (dar neincluse în preţ) legate de comisioanele sau cheltuielile de brokeraj (cu excepţia comisioanelor de cumpărare) saucosturi suportate în legătură cu containere şi ambalaje, mărfuri şi/sau servicii, furnizate de către cumpărător fie gratuit, fie la un cost redus, spre a fi utilizate la producerea şi vânzarea la export a mărfurilor importate.</li>
</ul>
`,
  },
  correct_steps: {
    name: "correct_steps",
    label:
      "b) Ce măsuri luaţi pentru a garanta corectitudinea stabilirii valorii în vamă (de exemplu, verificări, controale interne, proceduri interne de lucru, instruiri periodice)?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
  },
  regularly_monitoring: {
    name: "regularly_monitoring",
    label:
      "c) Monitorizaţi regulat eficacitatea măsurilor de care dispuneți de stabilire a valorii în vamă a mărfurilor?",
  },
  register_monitoring: {
    name: "register_monitoring",
    label: "d) Păstraţi registre privind aceste măsuri?",
  },
  imported_overview: {
    name: "imported_overview",
    label:
      "1.3.4. a) Oferiți o imagine de ansamblu a originii, preferenţiale sau nepreferenţiale a mărfurilor importate.",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
    info: `1.3.4. Pentru întrebarea de la litera b), în mod normal, acţiunile la nivel intern trebuie să includă măsuri privind modul în care asiguraţi că:
  <ul>
<li>a) ţara exportatoare are dreptul de a adopta un comportament preferenţial, iar mărfurile beneficiază de o cotă preferenţială a taxei vamale;</li>
<li>b) se respectă principiul transportării directe şi principiul teritorialităţii, absenţa manipulării;</li>
<li>c) atunci când se solicită regimul preferenţial, sunt disponibile un certificat valabil, în original sau declaraţia de origine;</li>
<li>d) certificatul sau declaraţia de originese aplică transportului respectiv, iar regulile de origine sunt respectate;</li>
<li>e) nu există posibilitatea de dublă utilizare a certificatului sau a declaraţiei de origine;</li>
<li>f) regimurile preferenţiale pentru import sunt solicitate în perioada de valabilitate a certificatului sau adeclaraţiei de origine;</li>
<li>g) certificatele sau declaraţia de origine originale sunt reţinute ca parte a pistei de audit în condiţii de siguranţă şi securitate.</li>
</ul>
   Pentru întrebarea de la litera c), abordarea trebuie să ia în considerare o modalitate pentru a vă asigura că:
<ul>
<li>a) mărfurile sunt eligibile pentru un regim preferenţial de export, de exemplu, respectă regulile de origine;</li>
<li>b) toate documentele/calculele/costurile/descrierile necesare de proceduri care servesc la stabilirea originii preferenţiale şi emiterea unui certificat sau a unei declaraţii de origine sunt reţinute ca parte a pistei de audit în condiţii de siguranţă şi securitate.</li>
<li>c) documentele adecvate, de exemplu certificatul sau declaraţia de origine, sunt semnate şi emise în timp util de un angajat autorizat al personalului.</li>
<li>d) declaraţia de origine nu este emisă pentru transporturi cu valoare medie sau ridicată, în afara cazului în care autorităţile vamale vă acordă o aprobare în acest sens;</li>
<li>e) certificatele nefolosite sunt păstrate în condiţii de siguranţă şi securitate şi</li>
<li>f) certificatele sunt prezentate autorităţilor vamale la export, conform cerinţelor.</li>
</ul>`,
  },
  internal_actions: {
    name: "internal_actions",
    label:
      "b) Ce acțiuni interne aţi pus în aplicare pentru a verifica dacă țara de origine a mărfurilor importate este declarată corect?",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
  },
  issuing_procedure: {
    name: "issuing_procedure",
    label:
      "c) Descrieţi procedura dvs. în ceea ce priveşte emiterea certificatelor de origine pentru export și/sau întocmirea declarațiilor de origine pentru export. ",
    placeholder: "Descriere",
    textarea: true,
    nullValue: true,
  },
  has_transaction_duties: {
    name: "has_transaction_duties",
    label:
      "1.3.5. Efectuaţi tranzacții care fac obiectul taxelor anti-dumping sau a taxelor compensatorii?",
    placeholder: "Descriere",
    info: "1.3.5. Prezentaţi următoarele informaţii, dacă este cazul denumirile şi adresele producătorilor pentru ale căror produse trebuie să plătiţi taxe antidumping sau compensatorii.",
  },
  transaction_duties_description: {
    name: "transaction_duties_description",
    label: "Dacă răspunsul este da, oferiți detalii.",
    placeholder: "Descriere",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};

export const representStatistic = {
  represents: {
    label: "Reprezentanți",
  },
  represent_persons: {
    name: ["represent", "represent_persons"],
    label: "Reprezentant",
  },
  represent_persons_attachments: {
    name: ["represent", "represent_persons_attachments"],
    label: "Lista de reprezentanți",
    dependencies: [["represent", "carry_formalities"]],
  },
  name: {
    name: ["represent", "represent_persons", "name"],
    label: "Denumirea",
    dependencies: [["represent", "carry_formalities"]],
  },
  address: {
    name: ["represent", "represent_persons", "address"],
    label: "Adresa",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
    dependencies: [["represent", "carry_formalities"]],
  },
  idno: {
    name: ["represent", "represent_persons", "idno"],
    label: "Codul fiscal",
    placeholder: "XXXXXXXXXXXXXXXX",
    dependencies: [["represent", "carry_formalities"]],
  },
  direct_mode: {
    name: ["represent", "represent_persons", "direct_mode"],
    dependencies: [["represent", "carry_formalities"]],
    label: "Modul de reprezentare",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};

export const clientStatistic = {
  clients: {
    label: "Clienți",
  },
  name: {
    name: "name",
    label: "Numele",
    placeholder: "Ex: Lorem Ipsum Ltd.",
  },
  direct_mode: {
    name: "direct_mode",
  },
};
