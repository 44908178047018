import { notApplicable } from "../../components/form-utils/utils";

export const person = {
  name: {
    name: "name",
    label: "Denumire",
  },
  first_name: {
    name: "first_name",
    label: "Nume",
    placeholder: "Ceobanu",
  },
  last_name: {
    name: "last_name",
    label: "Prenume",
    placeholder: "Ilie",
  },
  address: {
    name: "address",
    label: "Adresa",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
  },
  birthday: {
    name: "birthday",
    label: "Data nașterii",
    placeholder: "Selectează data",
  },
  phone: {
    name: "phone",
    label: "Telefon",
    placeholder: "XX-XXX-XXX",
  },
  email: {
    name: "email",
    label: "E-mail",
    placeholder: "john@gmail.com",
  },
  idnp: {
    name: "idnp",
    label: "IDNP",
    placeholder: "XXXXXXXXXXXXX",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
    placeholder: "",
  },
  // Company fields
  idno: {
    name: "idno",
    label: "IDNO/Nr. de înregistrare",
    placeholder: "XXXXXXXXXXXXX",
  },
  registration_date: {
    name: "created",
    label: "Data înregistrării",
    placeholder: "Selectează data",
  },
  registration_place: {
    name: "address",
    label: "Locul de înregistrare",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
  },
  created: {
    name: "created",
    label: "Data înființării",
  },
  juridic: {
    name: "juridic",
  },
  physic: {
    name: "physic",
  },
  percentage_shares: {
    name: "percentage_shares",
    label: "Procent de acțiuni deținute",
  },
};

export const personAdvisory = {
  has_advisory: {
    name: "has_advisory",
    label: "Există consiliul consultativ?",
    defaultDepsValue: [{ advisers: [] }],
  },
  advisers: {
    name: "advisers",
    dependencies: ["has_advisory"],
  },
};

export const personCustomer = {
  attachments: {
    name: "attachments",
    label:
      "Documente confirmative privind standardele practice de competență sau calificări profesionale",
  },
  attachments_persons: {
    name: "attachments_persons",
    label: "Cazier judiciar",
  },
};

export const personContact = {
  departments_involved: {
    name: "departments_involved",
    label: (
      <>
        e{")"} Ce departamente, inclusiv cel de gestionare, aţi implicat în
        procesul pregătirii companiei dvs. pentru cererea de acordare a
        statutului de AEO? <br /> <br /> Datele ar trebui să includă numele și
        adresa completă, data nașterii și numărul national de identificare (de
        exemplu, codul numeric personal).
      </>
    ),
  },
  has_customs_consulting: {
    name: "has_customs_consulting",
    label: "Ați implicat Serviciul Vamal în procesul de consultare?",
    defaultDepsValue: [
      { customs_consulting: { true: null, false: notApplicable } },
    ],
  },
  customs_consulting: {
    name: "customs_consulting",
    label: "Dacă răspunsul este da, furnizaţi detalii",
    dependencies: ["has_customs_consulting"],
  },
  has_third_party_involved: {
    name: "has_third_party_involved",
    label: "Ați implicat părțile terțe în procesul de consultare?",
  },
  third_party_juridic: {
    name: "third_party_juridic",
    dependencies: ["has_third_party_involved"],
  },
  third_party_physic: {
    name: "third_party_physic",
    dependencies: ["has_third_party_involved"],
  },
  attachments_persons: {
    name: "attachments_persons",
    label: "Cazier judiciar",
  },
};
