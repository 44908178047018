import {
  BorderedCard,
  KeyValueList,
  KeyValueRow,
  WhiteSpace,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useFieldValue, useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize, makeNamePath, prefFieldName } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm, PersonListProps } from "../components";
import { NoteForm } from "../components/NoteForm";

export const CustomsStatisticValues = ({ path }: PersonListProps) => {
  const statistic = fields.customsStatistic;
  const represent = fields.representStatistic;
  const client = fields.clientStatistic;

  const value = useFieldValue(path);

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <BorderedCard>
        <KeyValueCard {...statistic.carry_formalities} type="boolean" />
        <div className="color-gray">{statistic.represent_persons.label}</div>
        <KeyValueList
          title={represent.represents.label}
          name={["represent", "represent_persons", "value"]}
          ItemContent={({ prefix }) => (
            <>
              <KeyValueRow
                {...represent.name}
                name={makeNamePath(prefix, "name")}
              />
              <KeyValueRow
                {...represent.idno}
                name={makeNamePath(prefix, "idno")}
              />
              <KeyValueRow
                {...represent.address}
                name={makeNamePath(prefix, "address")}
              />
              <KeyValueRow
                {...represent.direct_mode}
                name={makeNamePath(prefix, "direct_mode")}
                type="direct-mode"
              />
              <WhiteSpace v="1rem" />
              <NoteForm note={value?.represent?.represent_persons?.note} />
            </>
          )}
        />
        <KeyValueCard
          {...represent.represent_persons_attachments}
          type="attachments"
        />
        <KeyValueCard {...statistic.has_represent} type="boolean" />
        <KeyValueList
          title={client.clients.label}
          name={["clients", "value"]}
          ItemContent={({ prefix }) => (
            <>
              <KeyValueRow {...prefFieldName(client.name, prefix)} />
              <KeyValueRow
                {...prefFieldName(client.direct_mode, prefix)}
                type="direct-mode"
                label="Modul"
              />
              <WhiteSpace v="1rem" />
              <NoteForm note={value?.clients?.note} />
            </>
          )}
        />
        <KeyValueCard {...statistic.attachments_clients} type="attachments" />
      </BorderedCard>
      <WhiteSpace v="2rem" />
      <BorderedCard>
        <KeyValueCard {...statistic.who_decide_classification} />
        <KeyValueCard {...statistic.classification_measures} />
        <KeyValueCard {...statistic.register_measures} type="boolean" />
        <KeyValueCard {...statistic.monitoring_measures} type="boolean" />
        <KeyValueCard {...statistic.resource_measures} />
      </BorderedCard>
      <WhiteSpace v="2rem" />

      <BorderedCard>
        <KeyValueCard {...statistic.determine_values} />
        <KeyValueCard {...statistic.correct_steps} />
        <KeyValueCard {...statistic.regularly_monitoring} type="boolean" />
        <KeyValueCard {...statistic.register_monitoring} type="boolean" />
      </BorderedCard>

      <WhiteSpace v="2rem" />
      <BorderedCard>
        <KeyValueCard {...statistic.imported_overview} />
        <KeyValueCard {...statistic.internal_actions} />
        <KeyValueCard {...statistic.issuing_procedure} />
        <KeyValueCard {...statistic.has_transaction_duties} type="boolean" />
        <KeyValueCard {...statistic.transaction_duties_description} NA />
      </BorderedCard>

      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...statistic.attachments} type="attachments" />
    </>
  );
};

export const CustomsStatistic = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.statistic.title}
      content={<CustomsStatisticValues />}
      path="saq/statistic"
    />
  );
};
