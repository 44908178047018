import { notApplicable } from "../../components/form-utils/utils";
import { extraTextareaProps } from "../common";

export const logisticOne = {
  has_audit_trail: {
    name: "has_audit_trail",
    label:
      "3.1.1. Facilitează sistemul dvs. de contabilitate o pistă de audit completă pentru activităţile dvs.vamale sau pentru fluxul de mărfuri sau intrările în contabilitate relevante din punctul de vedere al taxelor?",
    info: "3.1.1. Pista de audit trebuie să includă: vânzările, achiziţiile şi comenzile de achiziţii, controlul inventarului, depozitarea (şi mişcările între spaţiile de depozitare), producţia, vânzările şi comenzile de vânzări, declaraţiile vamale şi documentaţia vamală, expedierea, transportul, contabilitatea, de exemplu, facturare, note de credit şi de debit, remiteri/plăţi.",
    defaultDepsValue: [{ audit_trail: { true: null, false: notApplicable } }],
  },
  audit_trail: {
    name: "audit_trail",
    label:
      "Dacă da, descrieţi caracteristicile principale ale acestei piste de audit.",
    dependencies: ["has_audit_trail"],
  },
  accounting_systems: {
    name: "accounting_systems",
    label: (
      <>
        3.2.1. Ce sistem informatic (hardware/software) utilizaţi în general
        pentru activitatea dvs. contabilă şi în special pentru aspectele de
        ordin vamal? Sunt cele două sisteme integrate?
        <br />
        Oferiți informaţii privind următoarele: <br />
        a) separarea funcțiilor între dezvoltare, testare și operațiune; <br />
        b) separarea funcţiilor între utilizatori; <br />
        c) controlul accesului (care/de către cine); <br />
        d) trasabilitatea între sistemul de contabilitate şi sistemul de
        declarare.
      </>
    ),
    info: `3.2.1. Indicaţi dacă utilizaţi: 

    1) Hardware precum:
    <ul>
    <li>a) exclusiv un calculator personal (PC) autonom;</li>
    <li>b) PC-uri care sunt legate în reţea;</li>
    <li>c) un sistem de calculatoare pe bază de server;</li>
    <li>d) un sistem bazat pe mainframe; etc.</li>
    </ul>
    2) software precum programele informatice care permit calculatorului să ruleze aplicaţii software în sprijinul activităţii economice, de exemplu, Windows, UNIX etc.
    3) sisteme precum (indicaţi numele furnizorului):
    <ul>
    <li>a) soluţie ERP complet integrată;</li>
    <li>b) combinaţie de aplicaţii software contabile şi logistice;</li>
    <li>c) soluţie software pentru întreprinderi axată pe întreprinderile mici şi mijlocii;</li>
    <li>d) soluţie software dezvoltată de sau pentru compania.</li>
    </ul>
    * În timpul procedurii de autorizare, va fi necesar să demonstraţi:
    <ul>
    <li>a) gradul de informatizare;</li>
    <li>b) platforma hardware disponibilă şi sistemul de operare aferent;</li>
    <li>c) separarea funcţiilor între dezvoltare, testare şi operaţiuni;</li>
    <li>d) separarea funcţiilor între utilizatori;</li>
    <li>e) modul în care este controlat accesul la diferitele componente ale sistemului;</li>
    <li>f) dacă au existat adaptări la pachetul standard;</li>
    <li>g) lista conturilor din registrul contabil;</li>
    <li>h) dacă sistemul foloseşte conturi intermediare de verificare;</li>
    <li>i) modul în care sunt înregistrate în registrul contabil obligaţiile de plată a drepturilor de import;</li>
    <li>j) dacă lucraţi pe bază de loturi;</li>
    <li>k) dacă există o legătură între administrarea stocurilor şi administrarea financiară;</li>
    <li>l) modul în care gestionaţi evidenţele atunci când acestea sunt menţinute de un furnizor terţ de software.</li>
    </ul>`,
    ...extraTextareaProps,
  },
  // has_local: {
  //   name: "has_local",
  //   label:
  //     "3.2.2. Dacă nu dețineți mărfuri autohtone, vă rugam să indicați „nu se aplică”.",
  // },
  can_differentiate: {
    name: "can_differentiate",
    label: `3.2.2. Sistemele dvs. logistice pot să facă o diferență între mărfurile străine şi cele autohtone? Dacă răspunsul este da, oferiți informații detaliate. Dacă răspunsul este nu sau nu dețineți mărfuri autohtone, vă rugam să indicați "nu / nu se aplică"`,
    defaultDepsValue: [{ differentiate: { true: null, false: notApplicable } }],
  },
  differentiate: {
    name: "differentiate",
    label: "Oferiți informații detaliate.",
    dependencies: ["can_differentiate"],
    ...extraTextareaProps,
  },
  it_activity: {
    name: "it_activity",
    label:
      "3.2.3. a) De către cine sunt desfăşurate activităţile dvs. informatice?",
    ...extraTextareaProps,
    info: "3.2.3. În cazul în care activităţile, de exemplu crearea datelor de referinţă sau introducerea datelor, sunt împărţite între mai multe subdiviziuni, indicaţi care sunt activităţile desfăşurate în fiecare subdiviziune.",
  },
  has_it_outsourced: {
    name: "has_it_outsourced",
    label:
      "b) Au fost externalizate activitățile informatice? În cazul în care răspunsul este da, oferiți denumirea, adresa, codul fiscal, detalii cu privire la compania sau companiile către  care au fost externalizate și modalitatea de gestionare a controalelor de acces pentru activitățile externalizate.",
    defaultDepsValue: [{ outsource_organizations: [] }],
  },
  has_internal_control: {
    name: "has_internal_control",
    label:
      "3.3.1. Dispuneți de procese interne în ceea ce priveşte sistemul de control intern în cadrul departamentelor: contabilitate, achiziţii, vânzări, pe domeniul vamal, de producţie, de gestionare a materialelor şi a mărfurilor, de logistică?",
    placeholder: "",
    info: "3.3.1. În timpul vizitei auditorilor, trebuie să prezentaţi dovezi potrivit cărora revizuiţi procedurile în mod regulat şi complet, documentaţi modificările şi înştiinţaţi personalul vizat cu privire la modificările respective.",
    defaultDepsValue: [
      { internal_control: { true: null, false: notApplicable } },
    ],
  },
  internal_control: {
    name: "internal_control",
    label:
      "În cazul în care răspunsul este da, prezentaţi o descriere succintă a acestora şi a modului în care sunt actualizate (de exemplu, acţiuni precum instrucţiunile la locul de muncă, formarea profesională a angajaţilor, instrucţiuni pentru verificarea defectelor şi mecanismele de corectare).",
    placeholder: "Detalii",
    dependencies: ["has_internal_control"],
  },
  has_audit_control: {
    name: "has_audit_control",
    label:
      "3.3.2. Procesele de control intern au făcut obiectul unui audit intern/extern?",
    info: `3.3.2. Exemple de tipuri de audit pot fi:audit intern în cadrul companiei sau efectuat de societatea-mamă;audit extern efectuat de către clienţi, contabili/auditori independenţi, autorităţile vamale sau alte autorități publice. 
      De asemenea, atunci când autorităţile vamale vizitează sediul, este necesar să le puneţi la dispoziţie rapoarte, precum şi dovezi privind măsurile de remediere luate pentru corectarea deficienţelor identificate.
      Prin date de referinţă sau dosare standard se înţeleg informaţii cheie privind compania, de exemplu,denumirea/numele şi adresele clienţilor, furnizorii, dosarele produselor, care cuprind informaţii privind descrierea mărfurilor, codurile acestora şi provenienţa etc.`,
  },
  is_include_parcels: {
    name: "is_include_parcels",
    label:
      "Acesta a inclus şi un audit al practicilor vamale pe care le îndepliniţi?",
    defaultDepsValue: [
      { recent_audit_report: null },
      { incorrect_data: null },
      { incorrect_registration: null },
    ],
  },
  recent_audit_report: {
    name: "recent_audit_report",
    label:
      "În cazul în care răspunsul este da, prezentaţi o copie a celui mai recent raport de audit.",
  },
  incorrect_registration: {
    name: "incorrect_registration",
    label:
      "a) Înregistrarea incorectă şi/sau incompletă a tranzacţiilor în sistemul contabil.",
    info: "3.3.3. Descrieți succint procedurile pentru verificarea fișierelor informatice (date permanente sau dosare principale)? Cum acoperă aceste proceduri următoarele riscuri:",
    ...extraTextareaProps,
  },

  incorrect_data: {
    name: "incorrect_data",
    label:
      "b) Utilizarea de date permanente incorecte sau de date neactualizate, precum numărul de articole şi codurile tarifare.",
    ...extraTextareaProps,
  },
  incorrect_business_processes: {
    name: "incorrect_business_processes",
    label: "c) Controlul inadecvat al proceselor de afaceri.",
    ...extraTextareaProps,
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};

export const outsourceOrganization = {
  name: {
    name: ["name"],
    label: "Denumirea",
    placeholder: "Ex: Lorem Ipsum Ltd.",
    related_name: "outsource_organizations",
  },
  address: {
    name: ["address"],
    label: "Adresa",
    placeholder: "Ex: Strada Lorem Ipsum",
    related_name: "outsource_organizations",
  },
  code_fiscal: {
    name: ["code_fiscal"],
    label: "Codul fiscal",
    placeholder: "XXXXXXXXXXXXXXXX",
    related_name: "outsource_organizations",
  },
  description: {
    name: ["description"],
    label: "Detalii",
    placeholder: "Detalii",
    related_name: "outsource_organizations",
    textarea: true,
    nullValue: true,
  },
};
