// FIXME: DEPRECATED, use just toUpperCase() instead
export const capitalize = (value: string) => value.toUpperCase();

export const capitalized = (s: string) =>
  s?.charAt(0).toUpperCase() + s?.slice(1);

/**
 * Any is used to ignore the type of the parameter
 * @param obj - { fist_name?: string, last_name?: string }
 * @param fallback - string = '-'
 * @returns user full name
 */
export const getFullName = (
  obj = {
    first_name: "",
    last_name: "",
  } as any,
) => {
  if (!obj?.first_name && !obj?.last_name) {
    return "---";
  }

  return `${obj?.first_name} ${obj?.last_name}`;
};

export const isStringOrBoolean = (value?: string | boolean) => {
  return typeof value === "string" || typeof value === "boolean";
};

export const getTextWithoutLegalNumbering = (str: string) =>
  / (.+)/.exec(str)?.[1];