import { Card } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import { WhiteSpace } from "@aeo/core/components";
import { makeNamePath } from "@aeo/core/utils";
import { useFieldValue } from "@aeo/core/hooks";

interface WrapperEntity {
  title?: string;
  i: number;
  children: React.ReactNode;
  noStyle?: boolean;
}

export interface KeyValueListProps {
  title?: string;
  name: NamePath;
  ItemContent: React.ComponentType<{ prefix: NamePath }>;
  Wrapper?: React.ComponentType<WrapperEntity>;
  ident?: string | number;
  noStyle?: boolean;
}

export const KeyValueList = ({
  title,
  name,
  ItemContent,
  Wrapper = DefaultWrapper,
  ident,
  noStyle,
}: KeyValueListProps) => {
  const values: any[] = useFieldValue(name);

  return (
    <div
      style={{
        marginLeft: typeof ident === "number" ? `${ident * 2}rem` : ident,
      }}
    >
      <h3 className="mt-6 mb-6">{title}</h3>

      {!!values?.length &&
        values?.map?.((_, i) => (
          <Wrapper
            key={i}
            title={title}
            children={<ItemContent prefix={makeNamePath(name, i)} />}
            noStyle={noStyle}
            i={i}
          />
        ))}
    </div>
  );
};

const DefaultWrapper = ({ i, noStyle, children }: WrapperEntity) => {
  return (
    <>
      <Card {...(!noStyle && { className: "bordered" })} key={i}>
        <Card.Body
          {...(noStyle && { className: "p-0" })}
          style={{ padding: 0 }}
        >
          {children}
        </Card.Body>
      </Card>
      <WhiteSpace v="1rem" />
    </>
  );
};
