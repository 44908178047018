import { extraTextareaProps } from "../common";

export const securityFour = {
  title_6_11_2: {
    title: `6.11.2. În ce măsură următoarele categorii de angajaţi fac obiectul verificărilor de securitate (de exemplu, verificări pentru a se confirma dacă persoana în cauză nu are cazier judiciar)`,
    info: `6.11.2	lit. a) şi b) În documentele vizate la subpunctul 6.1.2. lit. a) şi b) trebuie sa fie incluse şi proceduri. Acestea trebuie să vizeze modul în care potenţialii noi angajaţi sunt verificaţi înainte de recrutare; procesul de iniţiere şi de formare trebuie să includă instrucţiunile privind securitatea întreprinderii. Întregul personal nou trebuie să declare sub semnătură înţelegerea acestor aspecte. De asemenea, procedurile trebuie să vizeze măsurile care sunt luate în cazul în care angajaţii existenţi sunt transferaţi în zone sensibile din punctul de vedere al securităţii.`,
  },
  verification_identity_partners: {
    name: "verification_identity_partners",
    label:
      "6.10.1. Descrieţi succint modul în care compania verifică identitatea partenerilor comerciali pentru a garanta siguranţa lanţului de aprovizionare (căutarea informaţiilor înainte de acceptarea sau trimiterea comenzilor).",
    ...extraTextareaProps,
    info: "6.10.1. Răspunsul trebuie să poată fi sprijinit prin documente justificative. Autoritatea vamală se aşteaptă să vadă documentele justificative de care dispuneţi în sprijinul răspunsului. Aceste documente includ evidenţele verificărilor efectuate. Acestea trebuie să fie disponibile spre control în timpul vizitei.",
  },
  safety_partner_requirements: {
    name: "safety_partner_requirements",
    label:
      "6.10.2. a) Descrieţi succint ce cerințe de securitate și siguranță aplicați atunci cînd vă alegeți parteneri comerciali (de exemplu, declarații de securitate, cerințe contractuale, parteneri comerciali care au, de asemenea, statut de AEO).",
    ...extraTextareaProps,
    info: `<p>
    6.10.2. lit.a) şi b) Sunteţi considerat responsabil numai pentru partea care vă revine din lanţul de aprovizionare internaţional, pentru mărfurile aflate sub controlul  şi pentru unităţile pe care le exploataţi. Cu toate acestea, securitatea lanţului de aprovizionare depinde, de asemenea, de securitatea partenerilor  comerciali şi veţi depune orice efort posibil pentru a garantacă partenerii comercialiîndeplinesc cerinţele de securitate.  Cerinţele pentru furnizorii  pot include, de exemplu, ca toate mărfurile să fie marcate, sigilate, ambalate, etichetate într-un anumit mod, să facă obiectul verificărilor cu raze X-RAY etc., şi să respecte anumite standarde internaţionale stabilite.
Atunci când există astfel de cerinţe, procedurile  trebuie să includă:</p>
<ul>
<li>a)	dacă este posibil, vizite regulate la spaţiile societăţii furnizorului pentru a verifica dacă cerinţele sunt respectate;</li>
<li>b)	comunicarea acestor acorduri personalului  pentru verificarea conformităţii la sosirea mărfurilor;</li>
<li>c)	dispoziţii pentru ca personalul să poate raporta nereguli/incidente;</li>
<li>d)	controale de gestiune/de supraveghere pentru a asigura că personalul respectă aceste cerinţe;</li>
<li>e)	măsuri de remediere luate ca urmare a încălcărilor identificate ale acestor acorduri;</li>
<li>f)	revizuirea şi actualizarea procedurilor în mod regulat.</li>
</ul>
  <p>Autoritatea vamală se aşteaptă să vadă documentele justificative de care dispuneţi în sprijinul răspunsului. Aceste documente includ evidenţele verificărilor efectuate. Acestea trebuie să fie disponibile spre control în timpul vizitei.</p>
`,
  },
  safety_partner_procedure: {
    name: "safety_partner_procedure",
    label:
      "b) Descrieți succint modul în care este verificată respectarea acestor proceduri.",
    ...extraTextareaProps,
  },
  has_partner_breaches: {
    name: "has_partner_breaches",
    label:
      "6.10.3. În ultimul an, aţi detectat încălcări ale acordurilor de securitate pe care le-aţi încheiat cu partenerii?",
    dependencies: ["partner_breaches"],
    info: `<p>6.10.3. Răspunsul  trebuie sa poată fi sprijinit prin documente justificative. Autoritatea vamală se aşteaptă să vadă documentele justificative de care dispuneţi în sprijinul răspunsului. Aceste documente includ evidenţele verificărilor efectuate. Acestea trebuie să fie disponibile spre control în timpul vizitei.</p>
    <p>Autoritatea vamală se aşteaptă ca orice astfel de încălcări să se reflecte în documentele menţionate la nota de la subpunctul 6.1.2 lit. a) şi b) împreună cu actualizarea corespunzătoare şi cu contramăsurile suplimentare.</p>`,
  },
  partner_breaches: {
    name: "partner_breaches",
    label: "Dacă răspunsul este da, ce măsuri aţi luat?",
    dependencies: ["has_partner_breaches"],
  },
  security_requirements: {
    name: "security_requirements",
    label:
      "6.11.1. a) Descrieţi succint modul în care sunt tratate cerinţele de securitate şi siguranţă în politica de ocupare a forţei de muncă în cadrul companiei ? Cine este responsabil pentru acest domeniu?",
    ...extraTextareaProps,
    info: `6.11.1 lit. a), b) şi c) Politica de ocupare a forţei de muncă din cadrul societăţii trebuie să ia în considerare cerinţele de securitate pe baza evaluării riscurilor efectuate . Procedurile  trebuie să includă:
<ul>
<li>a) realizarea de verificări ale antecedentelor angajaţilor noi şi existenţi care vor lucra sau vor ocupa posturi sensibile din punctul de vedere al securităţii;</li>
<li>b) solicitarea şi obţinerea de recomandări în momentul recrutării;</li>
<li>c) identificarea posturilor critice din punctul de vedere al securităţii şi realizarea verificărilor necesare pentru a include atât condamnările executate, cât şi pe cele neexecutate;</li>
<li>d) cerinţa ca angajaţii să îşi informeze superiorul ierarhic dacă au făcut obiectul unei proceduri de eliberare pe cauţiune, dacă sunt parte în proceduri judecătoreşti aflate în desfăşurare şi cu privire la eventuale condamnări;</li>
<li>e) eliminarea accesului la sistemul informatic, returnarea cardului de acces atunci când un membru al personalului demisionează sau este concediat;</li>
<li>f) comunicarea de către personal a oricăror alte locuri de muncă.</li>
<li>g) Verificarea conformităţii trebuie să fie dovedită în mod corespunzător prin înscrierea iniţialelor şi a datei pe o evidenţă corespunzătoare a verificărilor realizate.</li>
</ul>
`,
  },
  has_security_procedure: {
    name: "has_security_procedure",
    label: "b) Există instrucțiuni scrise privind procedurile de securitate?",
    dependencies: ["security_procedure"],
  },
  security_procedure: {
    name: "security_procedure",
    label:
      "c) Descrieţi pe scurt cum este verificată respectarea acestor proceduri.",
    dependencies: ["has_security_procedure"],
    info: "6.11.2	lit. a) şi b) În documentele vizate la subpunctul 6.1.2. lit. a) şi b) trebuie sa fie incluse şi proceduri. Acestea trebuie să vizeze modul în care potenţialii noi angajaţi sunt verificaţi înainte de recrutare; procesul de iniţiere şi de formare trebuie să includă instrucţiunile privind securitatea întreprinderii. Întregul personal nou trebuie să declare sub semnătură înţelegerea acestor aspecte. De asemenea, procedurile trebuie să vizeze măsurile care sunt luate în cazul în care angajaţii existenţi sunt transferaţi în zone sensibile din punctul de vedere al securităţii.",
  },
  security_procedure_attachments: {
    name: "security_procedure_attachments",
    label: "Atașați document confirmativ",
  },
  checking_new_employees: {
    name: "checking_new_employees",
    label:
      "a) Noii angajaţi care vor lucra în domenii sensibile din punctul de vedere al securităţii;",
    ...extraTextareaProps,
  },
  checking_old_employees: {
    name: "checking_old_employees",
    label:
      "b) Angajaţii existenţi care urmează să fie transferaţi în domenii sensibile din punctul de vedere al securităţii.",
    ...extraTextareaProps,
  },
  access_employees: {
    name: "access_employees",
    label:
      "Cum se asigură imposibilitatea accesului fizic sau electronic al personalului în spaţiile sau la datele societăţii după părăsirea acestor spaţii?",
    ...extraTextareaProps,
  },
  has_safety_training: {
    name: "has_safety_training",
    label:
      "6.11.3. Angajaţii beneficiază de formare în materie de securitate şi siguranţă?",
    dependencies: ["safety_training"],
    info: "6.11.3. lit. a), b), c) şi d) Întregul personal trebuie să beneficieze de instruire adecvată privind cerinţele de securitate şi siguranţă, precum protocoalele de securitate, detectarea intruziunilor/alterărilor şi raportarea incidentelor şi a riscurilor asociate lanţurilor de aprovizionare internaţionale. O unitate sau un grup de persoane (intern sau extern) trebuie să fie responsabil de asigurarea instruirilor pentru angajaţi. Instruirea trebuie să fie actualizată în cazul în care intervin modificări şi trebuie ţinută o evidenţă a tuturor activităţilor de instruire. În cazul oricărui furnizor extern, trebuie să se obţină un contract privind nivelul serviciilor. Consultaţi şi subpunctul 6.12.1.",
    defaultDepsValue: [
      { has_annual_course: null },
      { has_conducted_sessions: null },
      { has_sessions_register: null },
    ],
  },
  safety_training: {
    name: "safety_training",
    label:
      "a) Dacă răspunsul este da, care este frecvenţa organizării sesiunilor de formare în materie de securitate şi siguranţă?",
    dependencies: ["has_safety_training"],
  },
  has_annual_course: {
    name: "has_annual_course",
    label: "b) Există un curs anual de actualizare a cunoştinţelor?",
    dependencies: ["has_safety_training"],
  },
  has_conducted_sessions: {
    name: "has_conducted_sessions",
    label:
      "c) Sunt aceste sesiuni realizate la nivel intern sau sunt oferite de un furnizor extern?",
    dependencies: ["has_safety_training"],
  },
  has_sessions_register: {
    name: "has_sessions_register",
    label: "d) Există registre scrise cu privire la formare?",
    dependencies: ["has_safety_training"],
  },
  fixed_term_employee: {
    name: "fixed_term_employee",
    label:
      "6.11.4. a) Specificaţi domeniile în care sunt utilizaţi salariații angajaţi pe durată determinată?",
    ...extraTextareaProps,
    info: `6.11.4. lit. a) şi b) Societatea trebuie să instituie cerinţe de securitate în ceea ce priveşte utilizarea personalului temporar. Procedurile trebuie să includă:
<ul>
<li>1. contracte cu agenţiile de recrutare care să detalieze nivelurile de verificări de securitate ce trebuie realizate asupra personalului înainte şi după numire;</li>
<li>2. utilizarea exclusivă a serviciilor agenţiilor cunoscute care îndeplinesc cerinţele;</li>
<li>3. standarde similare de securitate atât pentru personalul temporar, cât şi pentru cel permanent (a se vedea subpunctul 6.11.1). Trebuie să puneţi la dispoziţie toate aceste contracte în timpul vizitei.</li>
</ul>
Autoritatea vamală se aşteaptă ca salariații angajați pe o durată determinată să fi fost verificat la aceleaşi standarde ca şi salariații angajați pe o durată nedeterminată. Întrucât este o practică comună ca acest tip de personal să fie furnizat de o agenţie externă de recrutare de personal temporar, aceste agenţii trebuie să facă obiectul unor acorduri privind nivelul serviciilor (a se vedea şi subsecțiunea6.12) şi trebuie să existe proceduri pentru a se asigura că standardele prevăzute în aceste acorduri sunt menţinute de societate şi dovedite prin evidenţele.`,
  },
  has_security_standards: {
    name: "has_security_standards",
    label:
      "b) Sunt aceşti salariați verificaţi periodic în conformitate cu standardele de securitate?",
    dependencies: ["security_standards"],
  },
  security_standards: {
    name: "security_standards",
    label: "Dacă răspunsul este da, cum şi de către cine?",
    dependencies: ["has_security_standards"],
  },
  has_employee_instructions: {
    name: "has_employee_instructions",
    label: "Există instrucţiuni de securitate şi pentru aceşti salariați?",
  },
  has_contractual_services: {
    name: "has_contractual_services",
    label:
      "6.12.1. Compania utilizează servici pe bază contractuală, precum ar fi servicii de transport, agenţi de securitate, curăţenie, furnizare de bunuri, întreţinere etc.?",
    dependencies: ["contractual_services"],
    info: `6.12.1. lit. a), b) şi c) Pentru întrebările de la lit.a) și b) trebuie să aveţi pregătite pentru vizita autorităţii vamale toate contractele şi acordurile privind nivelul serviciilor, care vizează verificările identităţii angajaţilor, precum şi alte aspecte legate de toate aceste servicii contractate. Furnizaţi în timpul vizitei o listă a tuturor societăţilor şi să indicaţi serviciile pe care le prestează.
Pentru întrebarea de la lit. c) - descrieţi modul în care monitorizaţi contractul, gestionaţi neregulile şi revizuiţi procedurile. Justificaţi răspunsul făcând referire, dacă este cazul, la evaluarea riscurilor şi ameninţărilor descrisă la întrebarea  de la subpunctul 6.1.2 lit. a) şi b). Verificarea conformităţii trebuie să fie sprijinită prin dovezi adecvate care să fie semnate şi datate.`,
  },
  contractual_services: {
    name: "contractual_services",
    label:
      "a) Dacă răspunsul este da, descrieţi pe scurt ce servicii vă sunt furnizate şi în ce măsură (pentru cele care nu au fost descrise în secţiunile anterioare).",
    dependencies: ["has_contractual_services"],
  },
  has_written_agreements: {
    name: "has_written_agreements",
    label:
      "b) Există acorduri scrise cu furnizorii de servicii care conţin cerinţe de securitate?",
    dependencies: ["written_agreements"],
  },
  written_agreements: {
    name: "written_agreements",
    label:
      "c) Descrieţi succint cum este verificată respectarea procedurilor incluse în aceste acorduri.",
    dependencies: ["has_written_agreements"],
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
